import type { MasterData } from 'types/masterData'
import { isBot } from 'utils/isBot/IsBot'

import type { Storage } from 'cache/constants/constants'
import { authInfoOrchestratorService } from '../services/authInfoOrchestratorService'
import type { AuthOrchestratorData } from '../types/auth'
import { getSessionCacheStorage } from './cacheStorage/getSessionCacheStorage'
import { setSessionCacheStorage } from './cacheStorage/setSessionCacheStorage'

export const getSession = async (
	masterData: MasterData,
	storagePreference?: Storage
): Promise<AuthOrchestratorData | null> => {
	try {
		if (isBot()) {
			return null
		}
		const sessionCacheStorage = await getSessionCacheStorage(
			masterData,
			storagePreference
		)

		if (sessionCacheStorage) {
			return sessionCacheStorage
		}
	} catch (e) {
		console.error('Error getting session from cache storage', e)
	}
	const fetchSession = await authInfoOrchestratorService()

	if (fetchSession) {
		await setSessionCacheStorage(fetchSession, masterData, storagePreference)
		return fetchSession
	}

	return null
}
