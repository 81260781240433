export const COUNTRY = 'COUNTRY'
export const TOKEN = 'TOKEN'
export const LANGUAGE = 'LANGUAGE'
export const TIMESTAMP = 'TIMESTAMP'

export enum Storage {
	Cache = 'cache',
	IndexedDb = 'indexedDB',
	IndexedDbAndCache = 'IndexedDbAndCache',
	CacheAndIndexedDb = 'CacheAndIndexedDb',
}
