import { Storage } from './constants/constants'
import { CacheAndIndexedDb } from './domain/CacheAndIndexedDb'
import { CacheStorage } from './domain/CacheStorage'
import { IndexedDbStorage } from './domain/IndexedDbStorage'
import type { StorageClass } from './domain/Storage'
import type { StorageProps } from './types/types'

export const getStorageStrategy = (
	storageProps: StorageProps
): StorageClass => {
	switch (storageProps.storagePreference) {
		case Storage.Cache:
			return new CacheStorage(storageProps)
		case Storage.IndexedDb:
			return new IndexedDbStorage(storageProps)
		case Storage.IndexedDbAndCache:
		case Storage.CacheAndIndexedDb:
			return new CacheAndIndexedDb(storageProps)
		default:
			return new CacheAndIndexedDb(storageProps)
	}
}
