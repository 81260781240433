import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'
import { useMemo } from 'react'
import type { Storage } from '../../constants/constants'
import type { StorageFeatureFlag } from '../../types/types'

export const useStoragePreference = (): { storagePreference: Storage } => {
	const {
		variables: { Storage: storageFeatureFlag },
	} = useFeatureFlag<StorageFeatureFlag>(featureFlagKeys.Storage)

	const storagePreference: Storage = useMemo(
		() => storageFeatureFlag,
		[storageFeatureFlag]
	)

	return { storagePreference }
}
