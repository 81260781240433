import { Storage } from '../constants/constants'
import type { StorageProps } from '../types/types'
import { CacheStorage } from './CacheStorage'
import { IndexedDbStorage } from './IndexedDbStorage'
import type { StorageClass } from './Storage'

export class CacheAndIndexedDb implements StorageClass {
	cacheInstance: CacheStorage
	indexedDbInstance: IndexedDbStorage
	storagePreference: Storage | undefined

	constructor(props: StorageProps) {
		this.cacheInstance = new CacheStorage(props)
		this.indexedDbInstance = new IndexedDbStorage(props)
		this.storagePreference =
			props.storagePreference ?? Storage.CacheAndIndexedDb
	}

	async getKeyFromCache<T>(key: string): Promise<T | undefined> {
		const [cacheValue, indexedDbValue] = await this.settlePromises([
			this.cacheInstance.getKeyFromCache<T>(key),
			this.indexedDbInstance.getKeyFromCache<T>(key),
		])

		return Promise.resolve(this.decideStorage(cacheValue, indexedDbValue))
	}

	async getAllKeysFromCache<T>(): Promise<T | null> {
		const [cacheValues, indexedDbValues] = await this.settlePromises([
			this.cacheInstance.getAllKeysFromCache<Record<string, T>>(),
			this.indexedDbInstance.getAllKeysFromCache<T>(),
		])

		return this.decideStorage(
			cacheValues as unknown as T,
			indexedDbValues as unknown as T
		)
	}

	async addKeyToCache<T>(key: string, value: T): Promise<void> {
		const [cache, indexedDB] = await Promise.allSettled([
			this.cacheInstance.addKeyToCache<T>(key, value),
			this.indexedDbInstance.addKeyToCache<T>(key, value),
		])

		if (cache.status === 'rejected') {
			console.error('Error adding key to cache')
		}
		if (indexedDB.status === 'rejected') {
			console.error('Error adding key to indexedDB')
		}
	}

	private async settlePromises<T>(promises: Promise<T>[]): Promise<T[]> {
		return Promise.allSettled(promises).then((results) => {
			return results.map((result: PromiseSettledResult<Awaited<T>>) => {
				if (result.status === 'fulfilled') {
					return result.value
				}
				return result.reason
			})
		})
	}

	private decideStorage<T>(cacheValue: T, indexedDbValue: T): T {
		return this.storagePreference === Storage.IndexedDbAndCache
			? indexedDbValue
			: cacheValue
	}
}
