'use client'

import type { StorageClass } from 'cache/domain/Storage'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import {
	type Dispatch,
	type ReactNode,
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'

import { useStoragePreference } from 'cache/hooks/useStoragePreference/useStoragePreference'
import type React from 'react'
import { baseSession } from '../constants'
import type { Session } from '../types/session'
import { getSession } from '../utils/getSession'

export const SessionContext = createContext<Session | null>(null)
export const SetSessionContext = createContext<SessionActions | null>(null)
export const SessionCacheContext = createContext<StorageClass | null>(null)

export interface SessionActions {
	setSession: Dispatch<Session>
	handleSession: () => void
}

type SessionProviderProps = {
	readonly children: React.ReactNode
}

export function SessionProvider({ children }: SessionProviderProps): ReactNode {
	const [session, setSession] = useState(baseSession)
	const masterData = useMasterData()
	const { storagePreference } = useStoragePreference()

	const handleSession = useCallback(async () => {
		const sessionData = await getSession(masterData, storagePreference)
		if (sessionData) {
			setSession({ ...sessionData, isReady: true })
		}
	}, [masterData])

	const sessionValue = useMemo(
		() => ({ setSession, handleSession }),
		[handleSession]
	)

	useEffect(() => {
		if (!session.isReady) {
			handleSession()
		}
	}, [handleSession, session.isReady])

	return (
		<SessionContext.Provider value={session}>
			<SetSessionContext.Provider value={sessionValue}>
				{children}
			</SetSessionContext.Provider>
		</SessionContext.Provider>
	)
}
