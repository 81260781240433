import type { MasterData } from 'types/masterData'

import { COUNTRY, LANGUAGE, TIMESTAMP, TOKEN } from '../constants/constants'
import type { StorageProps } from '../types/types'
import type { StorageClass } from './Storage'

class IndexedDbStorage implements StorageClass {
	cacheKey: string
	maxDiffTimestamp?: number
	browserSupportsCache: boolean
	isTokenCache: boolean
	isCountryCache: boolean
	isLanguageCache: boolean
	cache: IDBDatabase | null
	languageISO?: string
	countryISO?: string
	token?: string
	masterData?: MasterData

	private static readonly REQUEST_IDB_ERROR_MESSAGE =
		'Request could not be created'

	constructor({
		cacheKey,
		maxDiffTimestamp,
		isTokenCache = false,
		isCountryCache = false,
		isLanguageCache = false,
		languageISO,
		countryISO,
		token,
		masterData,
	}: StorageProps) {
		this.cacheKey = cacheKey
		this.maxDiffTimestamp = maxDiffTimestamp
		this.browserSupportsCache = true
		this.isTokenCache = isTokenCache
		this.isCountryCache = isCountryCache
		this.isLanguageCache = isLanguageCache
		this.languageISO = languageISO
		this.countryISO = countryISO
		this.token = token
		this.cache = null
		this.masterData = masterData
	}

	public openDB(): Promise<IDBDatabase> {
		return new Promise((resolve, reject) => {
			const request = indexedDB.open(this.cacheKey, 1)

			if (!request) {
				reject(new Error('Failed to open IndexedDB'))
				return
			}

			request.onupgradeneeded = () => {
				const db = request.result
				if (!db.objectStoreNames.contains(this.cacheKey)) {
					db.createObjectStore(this.cacheKey, {
						autoIncrement: true,
					})
				}
			}

			request.onsuccess = () => resolve(request.result)
			request.onerror = (error) => {
				this.browserSupportsCache = false
				console.error(
					`Error while initializing cache (Cache KEY :${this.cacheKey}): ${JSON.stringify(error)}`
				)
				return reject(
					new Error(`Error opening the database ${this.cacheKey}`, {
						cause: request.error,
					})
				)
			}
		})
	}

	public readonly init = async () => {
		if (!this.cache) {
			try {
				this.cache = await this.openDB()

				if (
					(await this.didCacheExpire()) ||
					(await this.shouldCleanCache()) ||
					this.isAppFirstWebview()
				) {
					await this.clean()
				}

				await this.addTimestampToCache()

				if (this.isTokenCache) {
					await this.addTokenToCache()
				}

				if (this.isCountryCache) {
					await this.addCountryToCache()
				}

				if (this.isLanguageCache) {
					await this.addLanguageToCache()
				}
			} catch (error) {
				this.browserSupportsCache = false
				console.error(
					`Error while initializing cache (Cache KEY :${this.cacheKey}): ${error}`
				)
			}
		}
		return Promise.resolve()
	}

	async addKeyToCache<T>(key: string, value: T): Promise<void> {
		if (!this.cache) {
			await this.init()
		}

		return new Promise((resolve, reject) => {
			const transaction = this.cache?.transaction(this.cacheKey, 'readwrite')
			if (!transaction) {
				reject(new Error('Transaction could not be created'))
				return
			}

			const store = transaction.objectStore(this.cacheKey)
			const request = store.put(value, key)

			if (!request) {
				reject(new Error(IndexedDbStorage.REQUEST_IDB_ERROR_MESSAGE))
				return
			}

			request.onsuccess = () => {
				resolve()
			}
			request.onerror = () =>
				reject(new Error('Error adding to key', { cause: request.error }))
		})
	}

	async getKeyFromCache<T>(key: string): Promise<T | undefined> {
		if (!this.cache) {
			await this.init()
		}
		return new Promise((resolve, reject) => {
			const transaction = this.cache?.transaction(this.cacheKey, 'readonly')
			if (!transaction) {
				reject(new Error('Transaction could not be done'))
				return
			}
			const store = transaction.objectStore(this.cacheKey)
			const request = store.get(key)

			request.onsuccess = () => {
				resolve(request.result)
			}
			request.onerror = () =>
				reject(new Error('Error getting from key', { cause: request.error }))
		})
	}

	async getAllKeysFromCache<T>(): Promise<Record<string, T> | null> {
		if (!this.cache) {
			await this.init()
		}

		return new Promise((resolve, reject) => {
			const transaction = this.cache?.transaction(this.cacheKey, 'readonly')
			const store = transaction?.objectStore(this.cacheKey)

			if (!store) {
				reject(new Error('Object store could not be accessed'))
				return
			}

			const keysRequest = store.getAllKeys()
			const valuesRequest = store.getAll()

			if (!keysRequest || !valuesRequest) {
				reject(new Error('Requests could not be created'))
				return
			}

			keysRequest.onsuccess = () => {
				const keys = keysRequest.result as string[]
				valuesRequest.onsuccess = () => {
					const values = valuesRequest.result as T[]

					const result = this.mapKeys<T>(keys, values)
					resolve(result)
				}

				valuesRequest.onerror = () =>
					reject(
						new Error('Error accessing to values', {
							cause: valuesRequest.error,
						})
					)
			}

			keysRequest.onerror = () =>
				reject(
					new Error('Error accessing to keys', { cause: keysRequest.error })
				)
		})
	}

	private mapKeys<T>(keys: string[], values: T[]): Record<string, T> {
		return keys.reduce<Record<string, T>>((acc, key, index) => {
			acc[key] = values[index]
			return acc
		}, {})
	}

	public async clean(): Promise<void> {
		return new Promise((resolve, reject) => {
			if (!this.cache) {
				reject(new Error('IndexedDB is not initialized'))
			}

			const transaction = this.cache?.transaction(this.cacheKey, 'readwrite')
			const store = transaction?.objectStore(this.cacheKey)
			const request = store?.clear()

			if (!request) {
				reject(new Error(IndexedDbStorage.REQUEST_IDB_ERROR_MESSAGE))
				return
			}

			request.onsuccess = () => {
				resolve()
			}
			request.onerror = () => {
				reject(new Error('Error cleaning storage', { cause: request.error }))
			}
		})
	}

	public readonly getCacheTimestamp = async (): Promise<number | null> => {
		if (!this.cache) {
			await this.init()
		}
		return new Promise((resolve, reject) => {
			const transaction = this.cache?.transaction(this.cacheKey, 'readonly')
			const store = transaction?.objectStore(this.cacheKey)
			const request = store?.get(TIMESTAMP)

			if (!request) {
				reject(new Error(IndexedDbStorage.REQUEST_IDB_ERROR_MESSAGE))
				return
			}

			request.onsuccess = () => {
				resolve(request.result)
			}

			request.onerror = () =>
				reject(new Error('Error getting timestamp', { cause: request.error }))
		})
	}

	public readonly addTimestampToCache = async (): Promise<void> => {
		const cacheTimestamp = await this.getCacheTimestamp()

		if (cacheTimestamp) {
			return Promise.resolve()
		}

		return new Promise<void>((resolve, reject) => {
			const transaction = this.cache?.transaction(this.cacheKey, 'readwrite')
			const store = transaction?.objectStore(this.cacheKey)
			const request = store?.put(Date.now(), TIMESTAMP)

			if (!request) {
				reject(new Error('Request could not be created'))
				return
			}

			request.onsuccess = () => {
				resolve()
			}
			request.onerror = () =>
				reject(
					new Error('Error adding timestamp to storage', {
						cause: request.error,
					})
				)
		})
	}

	public readonly addTokenToCache = async (): Promise<void> => {
		if (!this.cache) {
			await this.init()
		}
		return new Promise((resolve, reject) => {
			const transaction = this.cache?.transaction(this.cacheKey, 'readwrite')
			if (!transaction) {
				reject(new Error('Transaction could not be created'))
				return
			}

			const store = transaction?.objectStore(this.cacheKey)
			const request = store?.put(this.token, TOKEN)

			if (!request) {
				reject(new Error(IndexedDbStorage.REQUEST_IDB_ERROR_MESSAGE))
				return
			}

			request.onsuccess = () => resolve()
			request.onerror = () =>
				reject(
					new Error('Error adding token to storage', { cause: request.error })
				)
		})
	}

	public readonly addCountryToCache = async (): Promise<void> => {
		if (!this.cache) {
			await this.init()
		}
		return new Promise((resolve, reject) => {
			const transaction = this.cache?.transaction(this.cacheKey, 'readwrite')
			const store = transaction?.objectStore(this.cacheKey)
			const request = store?.put(this.countryISO, COUNTRY)

			if (!request) {
				reject(new Error(IndexedDbStorage.REQUEST_IDB_ERROR_MESSAGE))
				return
			}

			request.onsuccess = () => resolve()
			request.onerror = () =>
				reject(
					new Error('Error adding country to storage', { cause: request.error })
				)
		})
	}

	public readonly addLanguageToCache = async (): Promise<void> => {
		if (!this.cache) {
			await this.init()
		}
		return new Promise((resolve, reject) => {
			const transaction = this.cache?.transaction(this.cacheKey, 'readwrite')
			const store = transaction?.objectStore(this.cacheKey)
			const request = store?.put(this.languageISO, LANGUAGE)

			if (!request) {
				reject(new Error(IndexedDbStorage.REQUEST_IDB_ERROR_MESSAGE))
				return
			}

			request.onsuccess = () => resolve()
			request.onerror = () =>
				reject(
					new Error('Error adding language to storage', {
						cause: request.error,
					})
				)
		})
	}

	public readonly didCacheExpire = async () => {
		const timestamp = await this.getCacheTimestamp()

		if (!timestamp) {
			return true
		}

		if (!this.maxDiffTimestamp || this.maxDiffTimestamp <= 0) {
			return false
		}

		const diff = Date.now() - timestamp

		return diff >= this.maxDiffTimestamp
	}

	public readonly isAppFirstWebview = () => {
		const { isApp } = this.masterData || {}
		const referrer = document.referrer
		const ignoreReferrerCheck = referrer.includes('hunter')

		return isApp && (ignoreReferrerCheck || !referrer)
	}

	public readonly shouldCleanCacheTypes = async (): Promise<boolean> => {
		if (this.isTokenCache) {
			return new Promise((resolve, reject) => {
				const transaction = this.cache?.transaction(this.cacheKey, 'readwrite')
				const store = transaction?.objectStore(this.cacheKey)
				const request = store?.get(TOKEN)

				if (!request) {
					reject(new Error(IndexedDbStorage.REQUEST_IDB_ERROR_MESSAGE))
					return
				}

				request.onsuccess = () => resolve(request?.result?.token !== this.token)
				request.onerror = () =>
					reject(
						new Error('Error accessing to storage token', {
							cause: request.error,
						})
					)
			})
		}

		if (this.isCountryCache) {
			return new Promise((resolve, reject) => {
				const transaction = this.cache?.transaction(this.cacheKey, 'readwrite')
				const store = transaction?.objectStore(this.cacheKey)
				const request = store?.get(COUNTRY)

				if (!request) {
					reject(
						new Error(
							'IndexedDbStorage.REQUEST_IDB_ERROR_MESSAGE not be created'
						)
					)
					return
				}

				request.onsuccess = () =>
					resolve(request?.result.country !== this.countryISO)
				request.onerror = () =>
					reject(
						new Error('Error accessing to storage country', {
							cause: request.error,
						})
					)
			})
		}

		if (this.isLanguageCache) {
			return new Promise((resolve, reject) => {
				const transaction = this.cache?.transaction(this.cacheKey, 'readwrite')
				const store = transaction?.objectStore(this.cacheKey)
				const request = store?.get(LANGUAGE)

				if (!request) {
					reject(
						new Error(
							'IndexedDbStorage.REQUEST_IDB_ERROR_MESSAGE not be created'
						)
					)
					return
				}

				request.onsuccess = () =>
					resolve(request?.result.language !== this.languageISO)
				request.onerror = () =>
					reject(
						new Error('Error accessing to storage language', {
							cause: request.error,
						})
					)
			})
		}

		return false
	}

	public readonly shouldCleanCache = () => {
		const needToClean =
			this.browserSupportsCache &&
			(this.isTokenCache || this.isCountryCache || this.isLanguageCache)

		if (needToClean) {
			return this.shouldCleanCacheTypes()
		}

		return false
	}
}

export { IndexedDbStorage }
