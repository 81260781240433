import type { StorageClass } from 'cache/domain/Storage'
import type { MasterData } from 'types/masterData'

import type { Storage } from 'cache/constants/constants'
import type { AuthOrchestratorData } from '../../types/auth'
import { sessionCache } from '../sessionCache'

type SessionCacheStorage = AuthOrchestratorData & { TIMESTAMP: number }

export const getSessionCacheStorage = async (
	masterData: MasterData,
	storagePreference?: Storage
): Promise<SessionCacheStorage | null> => {
	const sessionStorage: StorageClass = sessionCache(
		masterData,
		storagePreference
	)
	const session =
		await sessionStorage.getAllKeysFromCache<SessionCacheStorage>()

	if (session?.token) {
		return session as unknown as SessionCacheStorage
	}
	return null
}
