'use client'

import { getStorageStrategy } from 'cache/cache'
import type { Storage } from 'cache/constants/constants'
import type { StorageClass } from 'cache/domain/Storage'
import type { MasterData } from 'types/masterData'

import { DIFF_TIMESTAMP, SESSION_CACHE_KEY } from '../constants'

let instance: StorageClass | null = null

export const sessionCache = (
	masterData: MasterData,
	storagePreference?: Storage
): StorageClass => {
	if (!instance) {
		instance = getStorageStrategy({
			cacheKey: SESSION_CACHE_KEY,
			maxDiffTimestamp: DIFF_TIMESTAMP,
			masterData,
			storagePreference,
		})
	}
	return instance
}
